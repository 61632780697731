<template>
  <div class="col-12">
    <b-table style="overflow: auto; white-space: nowrap;" :items="items" :fields="fields" :sort-by.sync="sortBy"
             :sort-desc.sync="sortDesc" responsive="sm">
      <template v-slot:cell(aksi)="row">
        <b-button-group v-for="(item, index) in navigate">
          <router-link :to="item.to" :key="index">
            <b-button size="sm" class="mx-1" :variant="item.variant">
              <b-icon :icon="item.icon" class="mx-2"></b-icon>
              {{ item.name }}
            </b-button>
          </router-link>
        </b-button-group>
      </template>
    </b-table>
  </div>
</template>

<script>
import {BTable, BButton, BIcon, BButtonGroup, BBadge} from "bootstrap-vue";

export default {
  name: 'TableListBloodbags',
  data() {
    return {
      sortBy: 'age',
      sortDesc: false,
      fields: [
        {key: 'no', sortable: true},
        {key: 'pembeli', sortable: true},
        {key: 'jumlahBloodbags', sortable: true},
        {key: 'golDarah', sortable: true},
        {key: 'harga', sortable: true},
        {key: 'aksi', sortable: false}
      ],
      items: [
        {
          no: 1,
          pembeli: "Edinson Maryadi",
          jumlahBloodbags: 40,
          golDarah: "O+",
          harga: "Rp. 200.000",
        },
        {
          no: 2,
          pembeli: "Alfred D.Lassiter",
          jumlahBloodbags: 40,
          golDarah: "O+",
          harga: "Rp. 200.000",
        },
      ],
      navigate: [
        {
          name: 'Detail',
          icon: 'menu-up',
          variant: 'success-1',
          to: "/dashboards/bloodbags-selling"
        }
      ]
    };
  },
  components: {
    BTable,
    BButton,
    BIcon,
    BButtonGroup,
    BBadge
  },
};
</script>
