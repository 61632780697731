<template>
  <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0">
    <b-col cols="12" class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden">
      <h2>Penjualan Blood Bags</h2>
      <p class="hp-p1-body mb-2 text-black">Semua (27)</p>
      <div class="header d-flex justify-content-between">
        <router-link to="/dashboards/add-bloodbag-selling">
          <b-button class="add-btn" variant="primary-2">Tambah Daftar</b-button>
        </router-link>
        <div class="row no-gutters w-15">
          <b-input-group>
            <b-input-group-prepend>
              <b-button
                variant="outline-black-50"
                class="border-right-0 pr-0 pl-2"
              >
                <i class="ri-search-line remix-icon text-primary"></i>
              </b-button>
            </b-input-group-prepend>
            <b-form-input
              type="search"
              placeholder="Search"
              class="border-left-0"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
    </b-col>
    <TableListBloodbags />
    <PaginateBloodbags />
  </b-row>
</template>
<script>
import {
  BCol,
  BRow,
  BButton,
  BIcon,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
} from "bootstrap-vue";
import PaginateBloodbags from "./components/PaginateBloodbags";
import TableListBloodbags from "./components/TableListBloodbags";

export default {
  name: "BloodBagsSellings",
  components: {
    BRow,
    BCol,
    BButton,
    BIcon,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    TableListBloodbags,
    PaginateBloodbags,
  },
};
</script>

<style scoped>
/* mobile screen */
@media screen and (max-width: 576px) {
  .header {
    flex-direction: column;
    gap: 0.5rem;
  }

  .add-btn {
    float: right;
  }
}
</style>
